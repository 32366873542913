import en from 'vee-validate/dist/locale/en.json'

export default {
  'Welcome back!': 'Welcome back!',
  'Try Valuator for free!': 'Try Valuator for free!',
  appDescription: `
    <p class="mb-2">For the first 30 days, Valuator is entirely free, and during that time you can see firsthand how helpful it is with organizing the procurement process.</p>
    <p class="mb-2">Communicate with suppliers easily, send inquiries with one click, get a clear overview of and easy access to all offers, get analytics, generate reports and select the best offer.</p>
    <p class="mb-2">Valuator – all in one place</p>
  `,
  loginAppDescription: 'Simple communication with your own suppliers, sending requests for offers, a clear overview of all received offers, choosing the best, analytics, creating reports. All in one place.',
  'Forgot Password?': 'Forgot Password?',
  'Do not have an account?': 'Do not have an account?',
  'Create an account': 'Create an account',
  'Make an account': 'Make an account',
  'Already have an account?': 'Already have an account?',
  'Sign in instead': 'Sign in instead',
  'I agree to': 'I agree to',
  'Remember Me': 'Remember Me',
  Login: 'Login',
  Logout: 'Logout',
  Home: 'Home',
  Documents: 'Documents',
  Add: 'Add',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Edit: 'Edit',
  View: 'View',
  Save: 'Save',
  Send: 'Send',
  Profile: 'Profile',
  Confirm: 'Confirm',
  Description: 'Description',
  Category: 'Category',
  Company: 'Company',
  Files: 'Add files',
  PIB: 'PIB',
  Accept: 'Accept',
  Reject: 'Reject',
  'Deadline for response': 'Deadline for response',
  'Delivery deadline': 'Delivery deadline',
  'Select status': 'Select status',
  'Select category': 'Select category',
  'Delivery date': 'Delivery date',
  Back: 'Back',
  'No data available': 'No data available',
  'Terms of Condition': 'Terms of Condition',
  Close: 'Close',
  Yes: 'Yes',
  No: 'No',
  Status: 'Status',
  'Created at': 'Created at',
  Finances: 'Finances',
  'Finances overview': 'Finances overview',

  purchaseStatuses: {
    Active: 'Active',
    Valuation: 'Valuation',
    Closed: 'Closed',
    Canceled: 'Canceled',
    Initiated: 'Initiated',
    'Initiation approved': 'Initiation approved',
    Requested: 'Requested',
    Received: 'Received',
    Paid: 'Paid',
    'Ad hoc': 'Ad hoc',
    Locked: 'Closed - delivered',
    'Ad hoc approved': 'Ad hoc - approved',
  },
  Active: 'Active',
  Closed: 'Closed',
  'Cancel request': 'Cancel request',

  Suppliers: 'Suppliers',
  Supplier: 'Supplier',
  'Select supplier': 'Select supplier',
  Categories: 'Categories',
  'Categories overview': 'Categories overview',
  'All suppliers': 'All suppliers',
  Warehouse: 'Warehouse',
  Receipts: 'Receipts',

  SuppliersEmptyText: '',
  Procurement: 'Procurement',
  Procurements: 'Procurements',
  'Procurements overview': 'Procurements overview',
  Request: 'Request',
  Number: 'no.',
  Offers: 'Offers',
  Offer: 'Offer',
  'New request': 'New request',
  'Requests overview': 'Requests overview',
  'Closed requests': 'Closed requests',
  'Confirmed requests': 'Confirmed requests',
  'Privacy policy': 'Privacy policy',
  'Format exm.': 'Format exm. +381652013555',

  successRegistrationMessage: 'You have successfully completed your registration. Check your email, we have sent you a link to confirm your registration.',

  'New category': 'New category',
  'Add new category': 'Add new category',
  'Edit category': 'Edit Category',
  'Confirm deletion': 'Confirm deletion',
  confirmDeletionQuestion: 'Are you sure you want to delete it? You can’t undo this.',
  'Yes, delete it': 'Yes, delete it',
  'No, just kidding': 'No, just kidding',
  'New supplier': 'New supplier',
  'Add new supplier': 'Add new supplier',
  'Edit supplier': 'Edit supplier',
  'New contact person': 'New contact person',
  'Save contact person': 'Save Contact person',
  'Update contact person': 'Update Contact person',
  Price: 'Price',
  Received: 'Received',
  'Offer Pending': 'Pending',
  'Offer Link opened': 'Link opened',
  'Offer Offer sent': 'Sent',
  'Offer Accepted': 'Accepted',
  'Offer Confirmed': 'Confirmed',
  'Offer Rejected': 'Rejected',
  'Initiation Approved': 'Initiation approved',
  'Initiation Rejected': 'Initiation rejected',
  'Purchase Requested': 'Requested',
  'Purchase Active': 'Active',
  'Purchase Rejected': 'Rejected',
  'Purchase Valuation': 'Valuation',
  'Purchase Closed': 'Closed',
  'Purchase Canceled': 'Canceled',
  'Purchase Initiated': 'Initiated',
  'Purchase Active Home': 'Active requests',
  'Purchase Valuation Home': 'Valuation requests',
  'Purchase Closed Home': 'Closed requests',
  'Purchase Canceled Home': 'Canceled requests',
  'Bid value Home': 'Total value of all purchases',
  'Accepted offers Home': 'Accepted offers',
  'Rejected offers Home': 'Rejected offers',
  'Active suppliers Home': 'Active suppliers',
  Note: 'Note',
  Quantity: 'Quantity',
  Accepted: 'Accepted',
  of: 'of',
  Undo: 'Undo',
  'Confirm request': 'Confirm request',
  'Request is closed': 'Request is closed',
  'Request is canceled': 'Request is canceled',
  'Request sent for verification': 'Request sent for verification',
  'By month': 'By month',
  'By week': 'By week',
  'By category': 'By category',
  'Number of purchases by month': 'Number of purchases by month',
  'Number of purchases by week': 'Number of purchases by week',
  'Number of purchases by category': 'Number of purchases by category',
  'Number of purchases by supplier': 'Number of purchases by supplier',
  'Number of purchases by customer': 'Number of purchases by customer',
  'Consumption by month': 'Consumption by month',
  'Consumption by week': 'Consumption by week',
  'Minimum and maximum value by category': 'Value by category (min-max)',
  'Minimum and maximum value by month': 'Minimum and maximum value by month',
  'Total consumption by category': 'Total consumption by category',
  'Chart type': 'Chart type',
  'View type': 'View type',
  'Minimum and maximum value for purchase': 'Minimum and maximum value for purchase',
  'Procurement number': 'Procurement number',
  'Choose number of procurement': 'Choose number of procurement',
  'Add supplier': 'Add supplier',
  'View suppliers': 'View suppliers',
  'There are no suppliers in this category': 'There are no suppliers in this category',
  'View categories': 'View categories',
  'User management': 'User management',
  Users: 'Users',
  'Create user': 'Create user',
  'All users': 'All users',
  'Are you confirming the deletion of the user': 'Are you confirming the deletion of the user',
  'Edit user': 'Edit user',
  'Can confirm purchase': 'Can confirm purchase',
  'Select role': 'Select role',
  'Are you sure you want to confirm the request': 'Are you sure you want to confirm the request',
  'Are you sure you want to submit a request for approval': 'Are you sure you want to submit a request for approval',
  'Send for approval': 'Send for approval',
  'Purchase management': 'Procurement management',
  'Purchase confirmation': 'Purchase confirmation',
  'Date from': 'Date from',
  'Date to': 'Date to',
  'Transportation included': 'Transportation included',
  'Transportation not included': 'Transportation not included',
  'Maximum amount to confirm': 'Maximum amount to confirm',
  Budget: 'Budget',
  Budgets: 'Budgets',
  budgetFor: '{categories} of {totalCategories} categories',
  'Budgets overview': 'Budgets overview',
  'Create budget': 'Create budget',
  'Edit budget': 'Edit budget',
  'You have successfully created a budget': 'You have successfully created a budget',
  'Select an existing contact person': 'Select an existing contact person',
  'Add category': 'Add category',
  'Can view the budget': 'Can view the budget',
  'Duplicate request': 'Duplicate request',
  Articles: 'Articles',
  Name: 'Name',
  'Order number': 'Order num.',
  'Unit price': 'Unit price',
  'Quantity requested': 'Quantity requested',
  'Quantity offered': 'Quantity offered',
  Projected: 'Projected',
  Spent: 'Spent',
  'Number / Value': 'Number / Value',
  Amount: 'Amount',
  'Comprehensive data from the platform': 'Comprehensive data from the platform',
  'Forgot your password?': 'Forgot your password?',
  'Enter your email and we will send you instructions for recovering a forgotten password': 'Enter your email and we will send you instructions for recovering a forgotten password',
  'Return to login page': 'Return to login page',
  'Choose avatar': 'Choose avatar',
  'Edit Profile': 'Edit Profile',
  Email: 'Email',
  Phone: 'Phone',
  'Change avatar': 'Change avatar',
  'Change your password': 'Change your password',
  'Password must contain at least 8 characters, one special character, one number, one uppercase and one lowercase letter.': 'Password must contain at least 8 characters, one special character, one number, one uppercase and one lowercase letter.',
  'Company information': 'Company information',
  'Request description': 'Request description',
  'Describe your request': 'Describe your request',
  'Do you confirm the deletion': 'Do you confirm the deletion',
  'Do you confirm the deletion of the offer': 'Do you confirm the deletion of the offer',
  'Are you sure you want to update the offer': 'Are you sure you want to update the offer',
  'Are you sure you want to submit an offer': 'Are you sure you want to submit an offer',
  'Do you confirm the deletion of the category': 'Do you confirm the deletion of the category',
  'Do you confirm the deletion of the supplier': 'Do you confirm the deletion of the supplier',
  'Do you confirm the deletion of the user': 'Do you confirm the deletion of the user',
  'You have successfully submitted your request': 'You have successfully submitted your request',
  'Wrong PIB code entered': 'Wrong PIB code entered',
  'Verification is required to place an offer': 'Verification is required to place an offer',
  'Please enter your PIB': 'Please enter your PIB',
  Instructions: 'Instructions',
  'Once the offer has been left, you can update it until the customer confirms the purchase.': 'Once the offer has been left, you can update it until the customer confirms the purchase.',
  'Your offer': 'Your offer',
  'Total price': 'Total price',
  'Requested qty.': 'Requested qty.',
  'Action confirmation': 'Action confirmation',
  'You have successfully submitted an offer.': 'You have successfully submitted an offer.',
  'You have successfully modified your offer.': 'You have successfully modified your offer.',
  'The supplier has not opened the request.': 'The supplier has not opened the request.',
  'Send again': 'Send again',
  'The supplier has opened a request but has not yet sent a bid.': 'The supplier has opened a request but has not yet sent a bid.',
  'Are you sure you want to accept the offer': 'Are you sure you want to accept the offer',
  'Are you sure you want to decline the offer': 'Are you sure you want to decline the offer',
  'Are you sure you want to resend the request to the supplier': 'Are you sure you want to resend the request to the supplier',
  'Are you sure you want to cancel the offer': 'Are you sure you want to cancel the offer',
  'Are you sure you want to cancel the request': 'Are you sure you want to cancel the request',
  'Procurement has expired': 'Procurement has expired',
  'You can no longer bid on this procurement.': 'You can no longer bid on this procurement.',
  'Procurement history for the period (number/value)': 'Procurement history for the period (number/value)',
  footerNav: {
    'All rights Reserved': 'All rights Reserved',
    'Terms of use': 'Terms of use',
    'Privacy policy': 'Privacy policy',
    'Cookie policy': 'Cookie policy',
  },
  Currency: 'Currency',
  'You have successfully added a category. You can now add a supplier.': 'You have successfully added a category. You can now add a supplier.',
  registerSuccess: {
    title: 'Registration successful!',
    errorTitle: 'Registration failed!',
    description: 'You have successfully completed your registration. <strong>Check your email</strong>, we have sent you a link to confirm your registration.',
  },
  createUserSuccessfulMessage: 'You have successfully created a user',
  newPasswordSentOnMail: 'A new password has been sent to your email, you will be redirected to the login page.',
  anErrorHasOccurred: 'An error has occurred.',
  sendingInProcess: 'Sending in progress...',
  passwordReset: 'Password reset',
  confirmationLinkSentToEmail: 'A confirmation link has been sent to your email',
  Role: 'Role',
  Details: 'Details',
  General: 'General',
  Security: 'Security',
  Address: 'Address',
  contractFileName: 'purchase',
  Confirmation: 'Confirmation',

  units: {
    kg: 'kg',
    m: 'm',
    m2: 'm2',
    m3: 'm3',
    t: 't',
    pieces: 'pieces',
    l: 'l',
  },

  chartsFilters: {
    title: 'Filters',
    buttonText: 'Apply filter',
    offersStatuses: {
      acceptedOffers: 'Accepted offers',
      declinedOffers: 'Declined offers',
    },
    noValues: {
      numberOffer: 'Number offer',
      valueOffer: 'Value offer',
    },
  },

  instructions: {
    title: 'What should I do now?!',
    step01: {
      title: 'Step: Create categories!',
      description: '(you are allowed to create an unlimited number of categories for the products and services you purchase)\n',
    },
    step02: {
      title: 'Step: Add the suppliers for the categories you created!',
      description: '(an unlimited number of suppliers may be added for each category)',
    },
    step03: {
      title: 'Step: Make a purchase request!',
      description: '(by creating a purchase request, you give suppliers all the details they need to respond to your request)',
    },
    step04: {
      title: 'Step: Choose the best offer!',
      description: '(review the supplier offers you have received and choose the best one)',
    },
    step05: {
      title: 'It\'s over!',
    },
  },
  passwordSuccessfullyChanged: 'Password successfully changed.',
  imageReplacedSuccessfully: 'Image edited successfully.',
  youHaveSuccessfullyChangedTheContactPerson: 'You have successfully changed the contact person',
  dataChangedSuccessfully: 'Data changed successfully.',
  confirmationRegistrationSuccessfully: 'Registration confirmation successful!',
  confirmationRegistrationFailed: 'Registration confirmation failed!',
  confirmationRegistrationMessage: 'You will receive a notification to the registered email address that you can log in to the system, after the moderator activates your account.',
  orderPlan: {
    title: 'Order package',
    month: 'month',
    months: 'months',
    preorderType: 'Preorder type',
    selectedPlan: 'Selected packet',
    order: 'Order',
    serbia: 'Serbia',
    ino: 'Ino',
    confirmationQuestion: 'Are you sure you want to order the package',
    successfulMessage: 'You have successfully ordered the package. We have sent a preorder to your email address.',
  },
  genders: {
    all: 'ALL',
    m: 'M',
    f: 'F',
  },
  register: {
    snackbarPib: {
      text: 'Do you want to fill in your company information?',
      yes: 'Yes',
      no: 'No',
    },
  },
  registrationNumber: 'Registration number',
  affiliate: {
    inviteButton: 'Invite',
    affiliateInfo: {
      text: 'Your affiliate link',
      copy: 'Copy',
      copiedLinkText: 'The affiliate link has been copied.',
    },
    userInfo: {
      title: 'Your information',
      name: 'Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      contractNumber: 'Contract number',
      companyName: 'Company name',
      pib: 'PIB',
      registrationNumber: 'Registration number',
    },
    affiliateTable: {
      title: 'Affiliate users',
      trialPeriodExpired: 'Expired',
      validUntil: 'Valid until',
    },
    inviteModal: {
      title: 'Invite users',
      infoTextLimit: 'You can send up to 10 invitations at once',
      emailsToInvite: 'Emails to invite',
      addEmailToInvite: 'Add emails to invite',
      successMessage: 'You have successfully invited users',
    },
    companyDetailsModal: {
      title: 'Details',
      pib: 'PIB',
      registrationNumber: 'Registration number',
      address: 'Address',
    },
    profitDetailsDialog: {
      title: 'Profit overview',
      transaction: 'Transaction',
      package: 'Package',
      amount: 'Amount',
      share: 'Share',
      paidOff: 'Paid off',
      noTransactionsMessage: 'There is no transactions yet',
    },
    commentDialog: {
      title: 'Comment',
    },
    dialogNote: {
      title: 'Note',
    },
  },

  faq: {
    faq01: 'How do I add categories and suppliers?',
    faq02: 'How do I initiate procurement?',
    faq03: 'How do I create users?',
  },
  switchToApp: 'Switch to App',
  switchToMarketplace: 'Switch to Marketplace',
  customer: 'Customer',
  offerDate: 'Offer date',
  addInvoice: 'Add invoice',
  addHocPurchase: 'Ad hoc purchase',
  'Create Ad hoc purchase': 'Create Ad hoc purchase',
  Storekeeper: 'Storekeeper',
  'Ad hoc purchase': 'Ad hoc purchases',
  'Ad hoc': 'Ad hoc',
  'Ad hoc approved': 'Ad hoc - approved',
  Paid: 'Paid',
  Invoice: 'Invoice',
  'Purchase order': 'Purchase order',
  'On hold': 'On hold',
  'Date for document': 'Date for document',
  'Date for receipt': 'Date for receipt',
  'Additional information': 'Additional information',
  'Realization suggestion rejected': 'Realization suggestion rejected',
  'History of negotiation': 'History of negotiation',
  Date: 'Date',
  User: 'User',
  Time: 'Time',
  'Request for supply': 'Request for supply',
  'Procurement history': 'Procurement history',
  'Procurement initiated': 'Procurement initiated',
  'Initiation approved': 'Initiation approved',
  'Procurement request proposed': 'Procurement request proposed',
  'Procurement announced': 'Procurement announced',
  'Implementation proposed': 'Implementation proposed',
  'Procurement completed': 'Procurement completed',
  'Goods / services received in the warehouse': 'Goods / services received in the warehouse',
  'Goods / services paid': 'Goods / services paid',
  'Reason for rejection': 'Reason for rejection',
  Negotiate: 'Negotiate',
  'Attach the delivery note': 'Attach the delivery note',
  Initiate: 'Initiate',
  Message: 'Message',
  'Do you confirm': 'Do you confirm',
  'Select the purchase value': 'Select the purchase value',
  'Up to four thousand euros': 'Up to four thousand euros',
  'More than four thousand euros': 'More than four thousand euros',
  'Purchase confirmation no.': 'Purchase confirmation no.',
  'Are you sure': 'Are you sure',
  'Mark as paid': 'Mark as paid',
  'Approve purchase': 'Approve purchase',
  'Advance payment': 'Advance payment',
  'Choose a payment type': 'Choose a payment type',
  'Standard payment': 'Standard payment',
  'Advanced payment': 'Advanced payment',
  'Payment method': 'Payment method',
  Locked: 'Closed - delivered',

  columns: {
    id: 'ID',
    request_number: 'REQUEST NO.',
    name: 'NAME',
    actions: 'ACTIONS',
    pib: 'PIB',
    status: 'STATUS',
    category: 'CATEGORY',
    created_at: 'CREATED AT',
    date_for_offer: 'RESPONSE DEADLINE',
    date_for_delivery: 'DATE FOR DELIVERY',
    email: 'EMAIL',
    role: 'ROLE',
    budget: 'BUDGET',
    spent: 'SPENT',
    date_from: 'DATE FROM',
    date_to: 'DATE TO',
    join_date: 'JOIN DATE',
    activated_date: 'ACTIVATED DATE',
    trial_period: 'TRIAL PERIOD',
    preorder: 'PREORDER',
    order: 'ORDER',
    price: 'PRICE',
    package: 'PACKAGE',
    duration: 'DURATION',
    company: 'COMPANY',
    offer_status: 'OFFER STATUS',
    sector: 'SECTOR',
  },

  fields: {
    name: 'Name',
    email: 'Email',
    password: 'Password',
    oldpassword: 'Old password',
    newpassword: 'New password',
    companyName: 'Company name',
    pib: 'PIB',
    nameAndSurname: 'Name and surname',
    phone: 'Phone',
    passwordConfirm: 'Password confirm',
    privacyPolicy: 'Privacy policy',
    description: 'Description',
    contactPerson: 'Contact person',
    category: 'Category',
    categories: 'Categories',
    file: 'Files',
    price: 'Price',
    total_price: 'Total price',
    unit_price: 'Unite price',
    date_for_offer: 'Deadline for response',
    date_for_delivery: 'Deadline for delivery',
    request_description: 'Request description',
    offer_description: 'Offer description',
    quantity: 'Quantity',
    unit: 'Unit',
    city: 'City',
    companyAddress: 'Company address',
    role: 'Role',
    category_id: 'Category',
    budget: 'Budget',
    date_from: 'Date from',
    date_to: 'Date to',
    amount: 'Amount',
    note: 'Note',
    offer_date: 'Offer date',
    addInvoice: 'Add invoice',
    sector: 'Sector',
    additional_permissions: 'Additional permissions',
    message: 'Message',
  },
  validation: {
    ...en.messages,
    ...{
      required_checkbox: 'The {_field_} field is required',
      array_length: 'The {_field_} must note be longer than {array_length} characters',
      phone_validation: 'The {_field_} must be formatted in the format +381652013555',
      minmax: 'The {_field_} must be between {min} and {max} digits',
      exist: 'The {_field_} already exist',
    },
  },
  filesValidation: 'Allowed file formats are .xls,.xlsx,.doc,.docx,.pdf,.jpg,.jpeg,.png, the allowed size of individual files is up to 5MB.',
}
