import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import router from '@/router'

if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    debug: process.env.NODE_ENV === 'development',
    vueRouter: router,
  })
}
